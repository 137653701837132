<template>
  <div>
    <div class="px-0 mb-7 pl-5">
      <InputGenericRadioOption
        id="input-single-choice"
        :model="$v.entry.answer"
        :options="computedChoices"
        :submissionStates="submissionStates"
        :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
        :displayLabel="false"
      >
      </InputGenericRadioOption>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";

import InputGenericRadioOption from "@/modules/together-helpers/components/generic-inputs/GenericRadioOption";

export default {
  mixins: [validationMixin],

  name: "SingleChoice",

  props: {
    question: { type: Object, required: true },
    submissionStates: { type: Object, required: true },
    subPosition: { type: Number, required: true }
  },

  validations: {
    entry: {
      answer: {
        required: requiredIf(function() {
          return this.question.required == 1;
        })
      }      
    }
  },

  components: {
    InputGenericRadioOption
  },

  methods: {
    testProgression: function() {
      this.$v.entry.$touch();
      return (this.$v.entry.$anyError) ? false : true;
    }
  },

  computed: {
    ...mapState({
      form: state => state.registerWorkflow.form.extended_profile_answers
    }),
    entry: function() {
      return this.form[this.subPosition];
    },
    computedChoices: function() {
      let options = [];
      this.question.extended_profile_choices.forEach(choice => {
        let option = choice;
        option.text = choice.choice;
        option.value = choice.id;
        options.push(option);
      });
      return options;
    }
  }
};
</script>
