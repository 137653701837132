<template>
  <div>
    <!--start::Error Handling-->
    <ErrorBox :errors="errors" @clear_errors="$emit('clear_errors')">
    </ErrorBox>
    <!--end::Error Handling-->

    <!--begin::Email-->
    <InputGeneric
      id="input-email"
      class="mb-7"
      :model="$v.form.email"
      alignLabel="left"
      :placeholder="$t('REGISTER.PERSONAL.EMAIL_CONFIRM')"
      :showInline="false"
      :submissionStates="submissionStates"
      :label="$t('REGISTER.PERSONAL.EMAIL_CONFIRM')"
      :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
      type="text"
      :disabled="this.currentUser.id != undefined"
    >
    </InputGeneric>
    <!--end::Email-->

    <!--begin::Name-->
    <InputGeneric
      id="input-name"
      v-if="!hasCompletedAccountSetup"
      class="mb-7"
      :model="$v.form.name"
      alignLabel="left"
      :placeholder="$t('REGISTER.PERSONAL.NAME')"
      :showInline="false"
      :submissionStates="submissionStates"
      :label="$t('REGISTER.PERSONAL.NAME')"
      :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
      type="text"
      :disabled="this.currentUser.id != undefined"
    >
    </InputGeneric>
    <!--end::Name-->

    <!--begin::Display Name-->
    <InputGeneric
      id="input-display"
      v-if="!hasCompletedAccountSetup"
      class="mb-10"
      :model="$v.form.display"
      alignLabel="left"
      :placeholder="$t('REGISTER.PERSONAL.DISPLAY')"
      :showInline="false"
      :submissionStates="submissionStates"
      :label="$t('REGISTER.PERSONAL.DISPLAY')"
      :error="$t('FORMS.ERROR.DISPLAY_NAME')"
      type="text"
      :disabled="this.currentUser.id != undefined"
    >
    </InputGeneric>
    <!--end::Display Name-->

    <div
      class="form-group d-flex flex-wrap justify-content-end align-items-center mb-0"
    >
      <router-link
        tag="a"
        :to="{ name: 'request_reset', params: { origin: 'register' } }"
        class="text-dark-50 text-hover-primary my-3 mr-2"
      >
        {{ $t("LOGIN.FORM.FORGOT") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  email,
  required,
  requiredIf,
  maxLength,
} from "vuelidate/lib/validators";

import ErrorBox from "@/view/components/ErrorBox";
import InputGeneric from "@/modules/together-helpers/components/generic-inputs/GenericTextInput";

export default {
  mixins: [validationMixin],

  name: "Personal",

  props: {
    submissionStates: { type: Object, required: true },
    hasCompletedAccountSetup: { type: Boolean, required: true },
    errors: { type: Object, required: true },
  },

  validations: {
    form: {
      email: {
        required,
        email,
      },
      name: {
        required: requiredIf(function () {
          return !this.hasCompletedAccountSetup;
        }),
        maxLength: maxLength(255),
      },
      display: {}
    },
  },

  components: {
    ErrorBox,
    InputGeneric,
  },

  mounted() {
    if (this.currentUser != null) {
      this.form.email = this.currentUser.calculatedEmail;
      this.form.name = this.currentUser.calculatedName;
      this.form.display = this.currentUser.display_name;
    }
  },

  methods: {
    testProgression: function () {
      this.$v.form.$touch();
      return this.$v.form.$anyError ? false : true;
    },
  },

  computed: {
    ...mapGetters(["currentUser"]),

    ...mapState({
      form: (state) => state.registerWorkflow.form,
    }),

    computedName: function () {
      if (this.form.name != undefined) {
        return this.form.name;
      } else {
        return null;
      }
    },

    computedDisplay: function () {
      return this.form.display;
    },
  },
};
</script>
