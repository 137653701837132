<template>
  <div>
    <!--start::Error Handling-->
    <ErrorBox :errors="errors" @clear_errors="$emit('clear_errors')">
    </ErrorBox>
    <!--end::Error Handling-->

    <!--start::Question Title-->
    <div class="row mx-0 d-flex justify-content-between mb-0">
      <div class="d-flex justify-content-start">
        <span class="pr-1">{{ (this.subPosition +1) + ". " }}</span>
        <span class="font-weight-bold mb-0 d-flex justify-content-start" v-html="currentQuestionWording"></span>
      </div>
      <span class="text-muted mb-0">{{ (subPosition +1) }} of {{ extendedProfileQuestions.length }}</span>
    </div>
    <!--end::Question Title-->

    <!--start::Question Stimulus-->
    <b-card-text
      v-if="currentQuestion.project_assets.length > 0"
      class="d-flex justify-content-center"
    >
      <InputGenericThumbGallery
        :projectAssets="currentQuestion.project_assets"
        :removable="false"
      >
      </InputGenericThumbGallery>
    </b-card-text>
    <!--end::Question Stimulus-->

    <component
      ref="active_sub_component"
      :is="currentComponent"
      :question="currentQuestion"
      :submissionStates="submissionStates"
      :subPosition="subPosition"
    >
    </component>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

import InputGenericThumbGallery from "@/modules/together-helpers/components/generic-inputs/generic_integrated_dropzone/GenericThumbGallery";

import ErrorBox from "@/view/components/ErrorBox";
import OpenEndedUploadQuestion from "./extended_profile/OpenEnded";
import MultipleChoiceUploadQuestion from "./extended_profile/MultipleChoice";
import SingleChoiceUploadQuestion from "./extended_profile/SingleChoice";
import PictureUploadQuestion from "./extended_profile/PictureUpload";
import VideoUploadQuestion from "./extended_profile/VideoUpload";

export default {
  name: "ExtendedProfile",

  props: {
    subPosition: { type: Number, required: true },
    submissionStates: { type: Object, required: true },
    errors: { type: Object, required: true }
  },

  components: {
    ErrorBox,
    InputGenericThumbGallery,
    OpenEndedUploadQuestion,
    MultipleChoiceUploadQuestion,
    SingleChoiceUploadQuestion,
    PictureUploadQuestion,
    VideoUploadQuestion
  },

  methods: {
    testProgression: function() {
      return this.$refs.active_sub_component.testProgression();
    }
  },

  computed: {
    ...mapGetters([
      "extendedProfileQuestions"
    ]),

    ...mapState({
      form: state => state.registerWorkflow.form.extended_profile_answers
    }),

    currentQuestion: function() {
      return this.extendedProfileQuestions[this.subPosition];
    },

    currentQuestionWording: function() {
      if (this.currentQuestion.required) {
        return this.currentQuestion.question + "*";
      } else {
        return this.currentQuestion.question;
      }
    },

    currentComponent: function() {
      switch (this.currentQuestion.system_answer_type_id) {
        case 1:
          return "OpenEndedUploadQuestion"
        case 2:
          return "MultipleChoiceUploadQuestion"
        case 3:
          return "SingleChoiceUploadQuestion"
        case 4:
          return "PictureUploadQuestion"
        case 6:
          return "VideoUploadQuestion"
        default:
          return null;
      }
    },
  }
}
</script>