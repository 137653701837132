<template>
  <div>
    <div class="px-0 mb-7">
      <!--Begin:TextArea-->
      <InputGenericTextArea
        class="col-xl-12 px-5 mb-5"
        id="input-open-ended"
        :model="$v.entry.answer"
        :submissionStates="submissionStates"
        :error="$t('FORMS.ERROR.REQUIRED')"
      >
      </InputGenericTextArea>
      <!--End:TextArea-->

      <!--Begin:DropZone-->
      <IntegratedDropzone
        v-if="question.allow_media"
        class="col-xl-12 px-0"
        :assetReservation="assetReservationData"
        :idx="question.id"
        :wholeWidth="true"
        :removable="true"
        classStyle="sm"
        :element="{ previews: []}"
        @asset_reservation_created="entry.attachment.push($event)"
        @asset_reservation_cancelled="entry.attachment = entry.attachment.filter(function(upload) { return upload !== $event })"
      >
      </IntegratedDropzone>
      <!--End: Drop Zone ---->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";

import IntegratedDropzone from "@/modules/together-helpers/components/generic-inputs/GenericIntegratedDropzone";
import InputGenericTextArea from "@/modules/together-helpers/components/generic-inputs/GenericTextArea";

export default {
  mixins: [validationMixin],

  name: "OpenEnded",

  props: {
    question: { type: Object, required: true },
    submissionStates: { type: Object, required: true },
    subPosition: { type: Number, required: true }
  },

  validations: {
    entry: {
      answer: {
        required: requiredIf(function() {
          return this.question.required == 1;
        })
      },
      attachment: {}      
    }
  },

  components: {
    IntegratedDropzone,
    InputGenericTextArea
  },

  methods: {
    testProgression: function() {
      this.$v.entry.$touch();
      return (this.$v.entry.$anyError) ? false : true;
    }
  },

  computed: {
    ...mapState({
      form: state => state.registerWorkflow.form.extended_profile_answers
    }),
    entry: function() {
      return this.form[this.subPosition];
    },
    assetReservationData: function() {
      let data = {
        scope: 'project',
        data: {
          directory: 'extended_profile_res_mm',
          projectId: window.location.host.split('.')[0]
        }
      }

      return data;
    }
  }
};
</script>