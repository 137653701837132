<template>
  <!--begin: Wizard Actions -->
  <div class="d-flex justify-content-end border-none pb-15">
    <div class="mr-2" :class="{ 'w-50' : dimensions.width <= 480 }">
      <button
        type="button"
        v-if="position > 0"
        @click="back"
        class="btn btn-outline-primary mr-2"
        :class="{ 'w-100' : dimensions.width <= 480 }"
      >
        {{ backText }}
      </button>
    </div>
    <div :class="{ 'w-50' : dimensions.width <= 480 }">
      <button
        type="button"
        @click="progress"
        class="btn btn-primary"
        :class="{ 'w-100' : dimensions.width <= 480 }"
      >
        {{ progressText }}
      </button>
    </div>
  </div>
  <!--end: Wizard Actions -->
</template>

<script>
export default {
  name: "RegisterFooter",

  props: {
    position: { type: Number, required: true },
    progressText: { type: String, required: true },
    backText: { type: String, required: true },
    dimensions: { type: Object, required: true }
  },

  methods: {
    back: function() {
      this.$emit('back');
    },
    progress: function() {
      this.$emit('progress');
    }
  }
}
</script>