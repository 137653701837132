<template>
  <div class="wizard-nav border-bottom">
    <div class="wizard-steps p-5">
      <template v-for="(item, i) in nav">
        <div
          :key="i"
          class="wizard-step"
          :class="{ 'active' : i == position, 'complete' : i < position, 'reached' : hasReached(i)}"
          data-wizard-type="step"
          @click="directNav(i)"
        >
          <div class="wizard-label">
            <i class="wizard-icon" :class="item.icon"></i>
            <h3 class="wizard-title mb-0">{{ (i + 1) + ". " + item.name }}</h3>
          </div>
          <i v-if="(i + 1) < nav.length" class="wizard-arrow flaticon2-next"></i>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterNav",

  props: {
    nav: { type: Array, required: true },
    position: { type: Number, required: true },
    maxPosition: { type: Number, required: true }
  },

  methods: {
    hasReached: function(position) {
      return (position <= this.maxPosition) ? true : false;
    },
    directNav: function(position) {
      (this.hasReached(position)) ? this.$emit('direct_nav',position) : null;
    }
  }
}
</script>