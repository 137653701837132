<template>
  <div v-if="loadingComplete">
    <!--start::Error Handling-->
    <ErrorBox :errors="errors" @clear_errors="$emit('clear_errors')">
    </ErrorBox>
    <!--end::Error Handling-->

    <!--start::Gender Question-->
    <div v-if="hasBasicProfileQuestion(1)">
      <label for="input-register-gender" class="d-block">{{ inputLabel($t('REGISTER.BASIC.GENDER'),1) }}</label>
      <InputGenericDropdown
        id="input-register-gender"
        class="mb-7"
        :model="$v.form.gender"
        labelDisplay="0"
        :options="systemGenders"
        optionLabel="name"
        value="id"
        :errorMessage="$t('FORMS.ERROR.REQUIRED')"
      >
      </InputGenericDropdown>
    </div>
    <!--end::Gender Question-->

    <!--start::DOB Question-->
    <div v-if="hasBasicProfileQuestion(2)">
      <label for="input-register-gender" class="d-block">{{ inputLabel($t('REGISTER.BASIC.DOB'),2) }}</label>
      <InputGenericDatePicker
        v-if="hasBasicProfileQuestion(2)"
        id="input-register-dob"
        class="mb-7"
        :model="$v.form.dob"
        :error="$t('FORMS.ERROR.REQUIRED')"
        type="date"
        :storedAsDate="true"
        :submissionStates="submissionStates"
        @date_updated="form.dob = $event"
      >
      </InputGenericDatePicker>      
    </div>
    <!--end::DOB Question-->

    <!--start::Address Question-->
    <div v-if="hasBasicProfileQuestion(3)">
      <label for="input-register-gender" class="d-block">{{ inputLabel($t('REGISTER.BASIC.ADDRESS'),3) }}</label>
      <InputGeneric
        id="input-address"
        class="mb-7"
        :model="$v.form.address"
        alignLabel="left"
        :placeholder="inputLabel($t('REGISTER.BASIC.ADDRESS'),1)"
        :showInline="false"
        :error="$t('FORMS.ERROR.REQUIRED')"
        type="text"
        :submissionStates="submissionStates"
      >
      </InputGeneric>
    </div>
    <!--end::Address Question-->

    <!--start::City Question-->
    <div v-if="hasBasicProfileQuestion(4)">
      <label for="input-register-gender" class="d-block">{{ inputLabel($t('REGISTER.BASIC.CITY'),4) }}</label>
      <InputGeneric
        id="input-city"
        class="mb-7"
        :model="$v.form.city"
        alignLabel="left"
        :placeholder="inputLabel($t('REGISTER.BASIC.CITY'),1)"
        :showInline="false"
        :error="$t('FORMS.ERROR.REQUIRED')"
        type="text"
        :submissionStates="submissionStates"
      >
      </InputGeneric>
    </div>
    <!--end::City Question-->

    <!--start::State Question-->
    <div v-if="hasBasicProfileQuestion(5)">
      <label for="input-register-gender" class="d-block">{{ inputLabel($t('REGISTER.BASIC.STATE'),5) }}</label>
      <InputGeneric
        id="input-state"
        class="mb-7"
        :model="$v.form.state"
        alignLabel="left"
        :placeholder="inputLabel($t('REGISTER.BASIC.STATE'),1)"
        :showInline="false"
        :error="$t('FORMS.ERROR.REQUIRED')"
        type="text"
        :submissionStates="submissionStates"
      >
      </InputGeneric>      
    </div>
    <!--end::State Question-->

    <!--start::Country Question-->
    <div v-if="hasBasicProfileQuestion(6)">
      <label for="input-register-gender" class="d-block">{{ inputLabel($t('REGISTER.BASIC.COUNTRY'),6) }}</label>
      <InputGenericDropdown
        id="input-register-country"
        class="mb-7"
        :model="$v.form.country"
        labelDisplay="0"
        :options="systemCountries"
        optionLabel="name"
        value="code"
        :errorMessage="$t('FORMS.ERROR.REQUIRED')"
      >
      </InputGenericDropdown>
    </div>
    <!--end::Country Question-->

  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";

import ErrorBox from "@/view/components/ErrorBox";
import PageLoader from "@/core/classes/PageLoader.js";
import InputGenericDropdown from "@/modules/together-helpers/components/generic-inputs/GenericDropdown";
import InputGenericDatePicker from "@/modules/together-helpers/components/generic-inputs/GenericDatePicker";
import InputGeneric from "@/modules/together-helpers/components/generic-inputs/GenericTextInput";

export default {
  mixins: [validationMixin],

  name: "BasicProfile",

  props: {
    submissionStates: { type: Object, required: true },
    errors: { type: Object, required: true }
  },

  mounted() {
    this.setGenderList();
    this.setCountryList();
  },

  data() {
    return {
      pageLoader: new PageLoader(2)
    }
  },

  validations: {
    form: {
      gender: {
        required: requiredIf(function() {
          return this.basicProfileQuestionRequired(1);
        }),
      },
      dob: {
        required: requiredIf(function() {
          return this.basicProfileQuestionRequired(2);
        }),
      },
      address: {
        required: requiredIf(function() {
          return this.basicProfileQuestionRequired(3);
        }),
      },
      city: {
        required: requiredIf(function() {
          return this.basicProfileQuestionRequired(4);
        }),
      },
      state: {
        required: requiredIf(function() {
          return this.basicProfileQuestionRequired(5);
        }),
      },
      country: {
        required: requiredIf(function() {
          return this.basicProfileQuestionRequired(6);
        }),
      }      
    }

  },

  components: {
    ErrorBox,
    InputGenericDropdown,
    InputGenericDatePicker,
    InputGeneric
  },

  methods: {
    hasBasicProfileQuestion: function(systemProfileQuestionId) {
      return this.basicProfileQuestions.filter(function( item ) {
        return item.system_profile_question_id == systemProfileQuestionId;
      }).length > 0;
    },
    basicProfileQuestionRequired: function(systemProfileQuestionId) {
      return this.basicProfileQuestions.filter(function( item ) {
        return item.system_profile_question_id == systemProfileQuestionId && item.required;
      }).length > 0;
    },
    testProgression: function() {
      this.$v.form.$touch();
      return (this.$v.form.$anyError) ? false : true;
    },
    inputLabel: function(initialLabel, systemActivityTypeId) {
      let required = this.basicProfileQuestions.filter(function( item ) {
        return item.system_profile_question_id == systemActivityTypeId && item.required;
      }).length > 0;

      return (required) ? initialLabel + "*" : initialLabel;
    },
    setGenderList: function () {
      let defaultOption = {
        id: null,
        name: `${this.$t("OTHER.REGISTRATION.PREFER_NOT_TO_SAY_OPTION")}`,
      };
      this.systemGenders.forEach((element) => {
        if (element.id !== null) element.name = `${this.$t("SYSTEM.GENDER." + element.id)}`;
      });
      if (!this._.includes(this.systemGenders, defaultOption)){
        this.systemGenders.unshift(defaultOption);
      }
      this.pageLoader.setCompleteComponent();
    },
    setCountryList: function () {
      let defaultOption = {
        id: null,
        name: `${this.$t("OTHER.REGISTRATION.PREFER_NOT_TO_SAY_OPTION")}`,
      };
      this.systemCountries.forEach((element) => {
        if (element.id !== null) element.name = `${this.$t("SYSTEM.COUNTRY." + element.id)}`;
      });
      if (!this._.includes(this.systemCountries, defaultOption)){
        this.systemCountries.unshift(defaultOption);
      }
      this.pageLoader.setCompleteComponent();
    },
  },

  computed: {
    ...mapState({
      form: state => state.registerWorkflow.form.basic_profile_answers
    }),
    ...mapGetters([
      "systemGenders",
      "systemCountries",
      "basicProfileQuestions"
    ]),
    required: function() {
      return this.basicProfileQuestions.filter(function( item ) {
        return item.system_profile_question_id == 7 && item.required;
      }).length > 0;
    },
    loadingComplete: function() {
      return this.pageLoader.getStatus();
    }
  },
};
</script>
