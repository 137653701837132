<template>
  <div>
    <div class="px-0 mb-7">
      <IntegratedDropzone
        class="col-xl-12 px-0"
        :assetReservation="assetReservationData"
        :idx="question.id"
        :wholeWidth="true"
        :removable="true"
        classStyle="sm"
        :element="{ previews: []}"
        @asset_reservation_created="entry.answer.push($event)"
        @asset_reservation_cancelled="entry.answer = entry.answer.filter(function(upload) { return upload !== $event })"
      >
      </IntegratedDropzone>

      <p
        v-if="submitAttempted && !canProgress"
        class="text-danger px-3 font-size-sm"
      >
        {{ $t('FORMS.ERROR.REQUIRED') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

import IntegratedDropzone from "@/modules/together-helpers/components/generic-inputs/GenericIntegratedDropzone";

export default {
  name: "VideoUpload",

  props: {
    question: { type: Object, required: true },
    submissionStates: { type: Object, required: true },
    subPosition: { type: Number, required: true }
  },

  data() {
    return {
      submitAttempted: false
    }
  },

  components: {
    IntegratedDropzone
  },

  methods: {
    testProgression: function() {
      if (this.canProgress) {
        return true;
      } else {
        this.submitAttempted = true;
        return false;
      }
    }
  },

  computed: {
    ...mapGetters([
      "extendedProfileQuestions"
    ]),
    ...mapState({
      form: state => state.registerWorkflow.form.extended_profile_answers
    }),
    entry: function() {
      return this.form[this.subPosition];
    },
    canProgress: function() {
      return (this.question.required && this.entry.answer.length > 0 || !this.question.required) ? true : false; 
    },
    assetReservationData: function() {
      let data = {
        scope: 'project',
        data: {
          directory: 'extended_profile_res_vid',
          projectId: window.location.host.split('.')[0]
        }
      }

      return data;
    }
  }
};
</script>
