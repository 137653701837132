<template>
  <div
    :class="{ 'fxt-content' : dimensions.width <= 480, 'wizard wizard-1' : dimensions.width > 480 }"
    id="kt_wizard_v1"
    data-wizard-state="step-first"
    data-wizard-clickable="true"
    style="min-width: 75%"
  >
    <!--begin: Wizard Nav-->
    <div v-if="dimensions.width > 480" style="height: 15vh">
      <RegisterNav
        :nav="nav"
        :position="position"
        :maxPosition="maxPosition"
        @direct_nav="directNav"
      >
      </RegisterNav>
    </div>
    <!--end: Wizard Nav-->

    <!--start:: Mobile Helpers-->
    <div v-else>
      <div class="text-center mb-7 mb-lg-10">
        <h3 class="mt-5 ml-3">Register</h3>
        <p class="text-muted font-weight-bold font-size-h6"> Step {{ position + 1 }} of {{ nav.length }} </p>
      </div>      
    </div>
    <!--end:: Mobile Helpers-->

    <!--begin: Wizard Body-->
    <div
      id="auth2_register_form"
      class="row justify-content-center px-0 px-lg-10 mb-10"
      :style="maxContentHeight"
    >
      <vuescroll>
        <div class="d-flex justify-content-center col-xs-12 col-sm-12 col-md-8 col-lg-8">
          <!--begin: Wizard Form-->
          <form class="form col-12 px-0" id="kt_form">
            <component
              ref="active_component"
              v-if="nav.length > 0"
              :is="nav[position].component"
              :hasCompletedAccountSetup="hasCompletedAccountSetup"
              :submissionStates="submissionStates"
              :subPosition="subPosition"
              :computedAccessLevel="computedAccessLevel"
              :errors="authenticationErrors"
              @clear_errors="onClearErrors"
            >
            </component>
          </form>
          <!--end: Wizard Form-->
        </div>
      </vuescroll>
    </div>
    <!--end: Wizard Body-->

    <div height="height: 15vh">
      <RegisterFooter
        :navLength="nav.length"
        :position="position"
        :progressText="progressText"
        :backText="backText"
        :dimensions="dimensions"
        @back="back"
        @progress="progress"
      >
      </RegisterFooter>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

import { REGISTER, CLEAR, LOGIN } from "@/modules/together-authentication/store/auth.module";

import PageLoader from "@/core/classes/PageLoader.js";

import vuescroll from 'vuescroll';

import RegisterNav from "./register/Nav";
import RegisterFooter from "./register/Footer";

import Personal from "./register/Personal";
import Password from "./register/Password";
import Avatar from "./register/Avatar";
import BasicProfile from "./register/BasicProfile";
import ExtendedProfile from "./register/ExtendedProfile";

export default {
  name: "Register2",

  props: {
    domainType: { type: Number, required: true },
    subDomain: { type: String, required: true },
    dimensions: { type: Object, required: true }
  },

  data() {
    return {
      pageLoader: new PageLoader(1),
      nav: [],
      position: 0,
      subPosition: 0,
      maxPosition: 0,
      submissionStates: {
        submitting: false
      }
    }
  },

  components: {
    vuescroll,
    RegisterNav,
    RegisterFooter,
    Personal,
    Password,
    Avatar,
    BasicProfile,
    ExtendedProfile
  },

  mounted() {
    this.buildNav();

    let self = this;
    this.mountQuestion(function() {
      self.pageLoader.setCompleteComponent(1);
    })
    this.form.hasCompletedAccountSetup = this.hasCompletedAccountSetup;
  },

  methods: {
    buildNav: function() {
      let nav = [];

      //Always push the personal tab
      nav.push(this.personalTab);

      //Only push the password tab if the user is not authenticated
      if (this.currentUser.id == undefined) {
        nav.push(this.passwordTab);
      }

      //Push the avatar tab if the user is new to the system (and not a participant), or if the project offers participants to upload an avatar
      if (
        (!this.hasCompletedAccountSetup && this.computedAccessLevel != "1") ||
        (this.basicProfileQuestions.filter(function( item ) { return item.system_profile_question_id == 7;}).length > 0)
      ) {
        nav.push(this.avatarTab);
      }

      //Push the basic profile tab if the invite is for a participant, and the project has basic profile questions
      if (this.computedAccessLevel == "1" && this.basicProfileQuestions.length > 0) {
        nav.push(this.basicProfileTab);
      }

      //Push the extended profile tab if the invite is for a participant, and the project has extended profile questions
      if (this.computedAccessLevel == "1" && this.extendedProfileQuestions.length > 0) {
        nav.push(this.extendedProfileTab);
      }

      this.nav = nav;
    },
    back: function() {
      if (this.subPosition > 0) {
        this.subPosition--;
      } else {
        this.position--;
      }
    },
    progress: function() {
      if (this.$refs.active_component.testProgression()) {
        switch (this.progressAction) {
          case 'SUBMIT':
            this.onSubmit();
            break;
          case 'NEXT_STEP':
            this.position++;
            this.incrementMaxPosition();
            break;
          case 'NEXT_QUESTION':
            this.subPosition++;
            break;
          default:
            this.position++;
            this.incrementMaxPosition();
            break;
        }
      }
    },
    mountQuestion: function(callback) {
      let self = this;
      this.extendedProfileQuestions.forEach(function (epQuestion) {
        switch (epQuestion.system_answer_type_id) {
          case 1:
            var prePop = epQuestion.pre_populate_response;
            var answer = (prePop == null || prePop.length == 0) ? null : prePop;
            self.form.extended_profile_answers.push({ id: epQuestion.id, answer: answer, attachment: []});
            break;
          case 2:
            self.form.extended_profile_answers.push({ id: epQuestion.id, answer: [] });
            break;
          case 3:
            self.form.extended_profile_answers.push({ id: epQuestion.id, answer: null });
            break;
          case 4: 
            self.form.extended_profile_answers.push({ id: epQuestion.id, answer: [] });
            break;
          case 6:
            self.form.extended_profile_answers.push({ id: epQuestion.id, answer: [] });
            break;
        }

      });
      callback();
    },
    onSubmit() {
      let payload = this.form;
      payload.display_name = payload.display; //Here be dragons

      payload.sub_domain = this.subDomain;
      payload.node = process.env.VUE_APP_SUBMODULE_IDENTIFIER;
      payload.grant_type = 'password';
      payload.client_id = process.env.VUE_APP_PASSPORT_ID;
      payload.client_secret = process.env.VUE_APP_PASSPORT_SECRET;

      //Set route based on domain type and access level
      if (this.domainType == 1 || this.domainType == 2) {
        payload.route = "/auth/register/admin";
      } else {
        payload.route = "/auth/register/projects/" + this.projectId + "/system_project_roles/" + this.computedAccessLevel;
        payload.project_id = this.projectId;
      }

      //Set Token
      payload.token = this.getTokenFromURL();

      this.$store.dispatch(REGISTER, payload)
        .then(() => {
          if (this.currentUser.id == undefined) {
            this.$store.dispatch(LOGIN, {
              username: payload.email,
              password: payload.password,
              sub_domain: payload.sub_domain,
              node: payload.node,
              grant_type: payload.grant_type,
              client_id: payload.client_id,
              client_secret: payload.client_secret
            }).then((res) => {
              window.location.href = res.data.data.redirect;
            })
          } else {
            if (process.env.VUE_APP_SUBMODULE_IDENTIFIER == "INPUT") {
              this.$router.push( { name: 'view_activities' } );
            } else {
              this.$router.push( { name: 'view_project' } );
            }
          }
        })
    },
    getTokenFromURL() {
      let token = this.$route.params.token;
      return token.replace("token=","");
    },
    onClearErrors: function() {
      this.$store.dispatch(CLEAR);
    },
    directNav: function(val) {
      this.subPosition = 0;
      this.position = val;
    },
    incrementMaxPosition: function() {
      if (this.position > this.maxPosition && this.maxPosition < this.nav.length -1) {
        this.maxPosition++;
      }
    }
  },

  computed: {
    ...mapGetters([
      "basicProfileQuestions",
      "extendedProfileQuestions",
      "projectBrandList",
      "authenticationErrors",
      "currentUser"
    ]),
    ...mapState({
      form: state => state.registerWorkflow.form
    }),
    loadingComplete() {
      return this.pageLoader.getStatus();
    },
    projectId: function() {
      if (this.projectBrandList.length > 0) {
        return this.projectBrandList[0].project_id;
      } else {
        return null;
      }
    },
    hasCompletedAccountSetup: function() {
      let token = this.$route.params.token;
      if (token.includes("-")) {
        return true;
      } else {
        return false;
      }
    },
    computedAccessLevel: function() {
      let token = this.$route.params.token;
      if (token.includes("-") || token.includes("!")) {
        return token.slice(token.length - 1);
      } else {
        return null;
      }
    },
    personalTab: function() {
      return {
        name: `${this.$t("OTHER.NAV.PERSONAL")}`,
        icon: 'flaticon-email',
        component: 'Personal',
        subPositions: 0
      };
    },
    passwordTab: function() {
      return {
        name: `${this.$t("OTHER.NAV.PASSWORD")}`,
        icon: 'flaticon-lock',
        component: 'Password',
        subPositions: 0
      };
    },
    avatarTab: function() {
      return {
        name: `${this.$t("OTHER.NAV.AVATAR")}`,
        icon: 'flaticon-avatar',
        component: 'Avatar',
        subPositions: 0
      };
    },
    basicProfileTab: function() {
      return {
        name: `${this.$t("OTHER.NAV.PROFILE")}`,
        icon: 'flaticon-clipboard',
        component: 'BasicProfile',
        subPositions: 0
      };
    },
    extendedProfileTab: function() {
      return {
        name: `${this.$t("OTHER.NAV.PROFILE_EXTENDED")}`,
        icon: 'flaticon2-plus',
        component: 'ExtendedProfile',
        subPositions: this.extendedProfileQuestions.length
      };
    },
    backAction: function() {
      if (this.nav.length > 0) {
        if (this.subPosition > 0) {
          return "LAST_QUESTION";
        } else {
          return "LAST_STEP";
        }
      } else {
        return null;
      }
    },
    progressAction: function() {
      if (this.nav.length > 0) {
        if (this.position == (this.nav.length -1) && ((this.subPosition +1) == this.nav[this.position].subPositions || this.nav[this.position].subPositions == 0)) {
          return 'SUBMIT'
        } else if (this.position < (this.nav.length -1) && (this.subPosition +1) == this.nav[this.position].subPositions) {
          return 'NEXT_STEP'
        } else if ((this.subPosition +1) < this.nav[this.position].subPositions) {
          return 'NEXT_QUESTION'
        } else {
          return 'NEXT'
        }
      } else {
        return null;
      }
    },
    backText: function() {
      switch (this.backAction) {
        case 'LAST_QUESTION':
          return `${this.$t("OTHER.FOOTER.LAST_QUESTION")}`;
        case 'LAST_STEP':
          return `${this.$t("OTHER.FOOTER.LAST_STEP")}`;
        default:
          return `${this.$t("OTHER.FOOTER.LAST_STEP")}`;
      }
    },
    progressText: function() {
      switch (this.progressAction) {
        case 'SUBMIT':
          return `${this.$t("OTHER.FOOTER.SUBMIT")}`;
        case 'NEXT_STEP':
          return `${this.$t("OTHER.FOOTER.NEXT_STEP")}`;
        case 'NEXT_QUESTION':
          return `${this.$t("OTHER.FOOTER.NEXT_QUESTION")}`;
        default:
          return `${this.$t("OTHER.FOOTER.NEXT")}`;
      }
    },
    maxContentHeight: function() {
      if (this.dimensions.width <= 480) {
        return "";
      } else {
        return "height: 70vh !important";
      }
    },
    asideLogo: function() {
      if (this.domainType == 1 || this.domainType == 2) {
        return "/media/logos/Logo-New.svg";
      } else {
        if (this.projectBrand[8].existing_asset.length > 0) {
          return this.projectBrand[8].existing_asset[0].signedUrl;
        } else {
          return "/media/logos/Logo-New.svg";
        }
      }
    },
  }
}
</script>

<style lang="scss">
  @import "@/assets/sass/pages/wizard/wizard-1.scss";

  #auth2_register_form .__vuescroll .__view {
    width: min-content !important;
  }
</style>