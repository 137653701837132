<template>
  <div>
    <!--start::Error Handling-->
    <ErrorBox :errors="errors" @clear_errors="$emit('clear_errors')">
    </ErrorBox>
    <!--end::Error Handling-->

    <!--begin::Password-->
    <InputGenericPassword
      id="input-password"
      class="mb-7"
      :model="$v.form.password"
      alignLabel="left"
      :placeholder="$t('REGISTER.PASSWORD.PASSWORD')"
      :showInline="false"
      :submissionStates="submissionStates"
      :label="$t('REGISTER.PASSWORD.PASSWORD')"
      :error="$t('FORMS.GENERIC_ERRORS.PASSWORD_COMPLEXITY')"
    >
    </InputGenericPassword>
    <!--end::Password-->

    <!--begin::Password Confirmation-->
    <InputGenericPassword
      id="input-password-confirm"
      v-if="!hasCompletedAccountSetup"
      class="mb-10"
      :model="$v.form.password_confirmation"
      alignLabel="left"
      :placeholder="$t('REGISTER.PASSWORD.CONFIRM_PASSWORD')"
      :showInline="false"
      :submissionStates="submissionStates"
      :label="$t('REGISTER.PASSWORD.CONFIRM_PASSWORD')"
      :error="$t('FORMS.GENERIC_ERRORS.PASSWORD_MATCH')"
    >
    </InputGenericPassword>
    <!--end::Password Confirmation-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";

import ErrorBox from "@/view/components/ErrorBox";
import InputGenericPassword from "@/modules/together-helpers/components/generic-inputs/GenericPasswordInput";

export default {
  mixins: [validationMixin],

  name: "Password",

  props: {
    submissionStates: { type: Object, required: true },
    hasCompletedAccountSetup: { type: Boolean, required: true },
    errors: { type: Object, required: true }
  },

  validations () {
    if (!this.hasCompletedAccountSetup) {
      return {
        form: {
          password: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(24),
            valid: function(value) {
              const containsUppercase = /[A-Z]/.test(value)
              const containsNumber = /[0-9]/.test(value)
              const containsSpecial = /['^£!$%&*()}{@#~?><>,.|=_+¬-]/.test(value)
              return containsUppercase && containsNumber && containsSpecial
            }
          },
          password_confirmation: {
            required,
            sameAsPassword: sameAs(function() {
              return this.form.password;
            })
          }
        }
      }
    } else {
      return {
        form: {
          password: {
            required
          },
          password_confirmation: {}
        }
      }
    }
  },

  components: {
    ErrorBox,
    InputGenericPassword
  },

  methods: {
    testProgression: function() {
      this.$v.form.$touch();
      return (this.$v.form.$anyError) ? false : true;
    }
  },

  computed: {
    ...mapState({
      form: state => state.registerWorkflow.form
    })
  }
};
</script>
