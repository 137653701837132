<template>
  <div>
    <!--start::Error Handling-->
    <ErrorBox :errors="errors" @clear_errors="$emit('clear_errors')">
    </ErrorBox>
    <!--end::Error Handling-->

    <label for="input-register-picture" class="d-block px-1">
      {{ label }}
    </label>

    <!--Begin: Drop Zone ---->
    <IntegratedDropzone
      class="col-xl-12 px-0 mb-2"
      :assetReservation="assetReservationData"
      :idx="1"
      :wholeWidth="true"
      :removable="true"
      classStyle="sm"
      :element="{ previews: []}"
      @asset_reservation_created="form.avatar = $event"
      @asset_reservation_cancelled="form.avatar = null"
    >
    </IntegratedDropzone>
    <!--End: Drop Zone ---->

    <p
      v-if="submitAttempted && !canProgress"
      class="text-danger px-3 font-size-sm"
    >
      {{ $t('FORMS.ERROR.REQUIRED') }}
    </p>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";

import ErrorBox from "@/view/components/ErrorBox";
import IntegratedDropzone from "@/modules/together-helpers/components/generic-inputs/GenericIntegratedDropzone";

export default {
  mixins: [validationMixin],

  name: "Avatar",

  props: {
    errors: { type: Object, required: true },
    computedAccessLevel: { type: String, required: true }
  },

  data() {
    return {
      submitAttempted: false
    }
  },

  components: {
    ErrorBox,
    IntegratedDropzone
  },

  methods: {
    testProgression: function() {
      if (this.canProgress) {
        return true;
      } else {
        this.submitAttempted = true;
        return false;
      }
    }
  },

  computed: {
    ...mapGetters([
      "basicProfileQuestions"
    ]),
    ...mapState({
      form: state => state.registerWorkflow.form.basic_profile_answers
    }),
    assetReservationData: function() {
      let data = {
        scope: 'user',
        data: {
          directory: 'avatars',
          new: 1
        }
      }
      return data;
    },
    required: function() {
      if (this.computedAccessLevel == "1") {
        return this.basicProfileQuestions.filter(function( item ) {
          return item.system_profile_question_id == 7 && item.required;
        }).length > 0;        
      } else {
        return false;
      }
    },
    canProgress: function() {
      return (this.required && typeof this.form.avatar == 'number' || !this.required) ? true : false; 
    },
    label: function() {
      if (this.required) {
        return `${this.$t("REGISTER.BASIC.AVATAR")}` + "*";
      } else {
        return `${this.$t("REGISTER.BASIC.AVATAR")}`;
      }

    }
  },
};
</script>